
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmUploadAvatar from '@/components/shared/TmUploadAvatar.vue'

export default defineComponent({
  components: {
    TmModal,
    TmUploadAvatar,
    TmButton,
    TmFormLine,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Edit user details',
    },
  },
  setup() {
    const account = ref({
      firstName: 'Ruth',
      lastName: 'Adkins',
      username: 'adkins.r',
      role: 'Agent',
      team: [
        'Admin',
        'Marketing',
      ],
      email: 'ruth.agent@textmagic.com',
      phone: '(427) 624-0500',
      address: '971 Cummings Flats, London',
      avatar: {
        name: 'Ruth Adkins',
        avatarColor: 'red',
        url: '',
      },
    })

    const roleList = ref([
      'Administrator',
      'Agent',
      'Supervisor',
      'Accountant',
    ])

    const teamList = ref([
      'Admin',
      'Agent',
      'Supervisor',
      'Accountant',
      'Marketing',
    ])

    const uploadAvatar = () => {
      account.value.avatar.url = 'httms://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=100'
    }
    const deleteAvatar = () => {
      account.value.avatar.url = ''
    }

    return {
      account,
      roleList,
      teamList,
      uploadAvatar,
      deleteAvatar,
    }
  },
})
